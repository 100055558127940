import React from "react"
import { Link } from "gatsby"

const Agencies = ({ agencies }) => {
  return (
    <div className="w-full h-full py-10">
      <div className="container mx-auto grid gap-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-4 px-5 md:px-0">
        {agencies.map((agency, i) => {
          return (
            <Link
              to={`/agency/${agency.node.slug}`}
              key={`agency__${agency.node.strapiId}`}
            >
              <div className="bg-white rounded-lg shadow-md">
                <div className="p-2">
                  <p className="font-medium text-md font-medium">
                    {agency.node.title}
                  </p>
                  <p className="text-xs text-gray-600">{agency.node.address}</p>
                </div>

                <div className="bg-gray-100 border-t border-b border-gray-200">
                  <img
                    className="w-full h-32"
                    src={agency.node.image.url}
                    alt={agency.node.image.url}
                    height="100"
                  />
                </div>

                <div className="grid gap-2 grid-cols-2 p-2">
                  <div className="">
                    <p className="text-xs font-medium text-gray-600">Rating</p>
                    <span className="flex">
                      <i className="fas fa-star text-orange-500 self-center mr-1"></i>
                      <p className="text-md font-medium">
                        {agency.node.rating}
                      </p>
                    </span>
                  </div>

                  <div className="">
                    <p className="text-xs font-medium text-gray-600">Reviews</p>
                    <p className="text-md font-medium">29</p>
                  </div>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Agencies
