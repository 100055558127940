import React from "react"
import { StaticQuery, graphql } from "gatsby"
import CookiesModul from "../components/cookiesmodul"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AgencyComponent from "../components/agency"
import Footer from "../components/footer"

import waveReverse from "../images/wave_reverse.svg"

const LeadPages = () => (
  <Layout>
    <Seo title="Lead Pages" description="Lead Pages" />
    <StaticQuery
      query={graphql`
        query {
          allStrapiAgency {
            edges {
              node {
                title
                strapiId
                rating
                address
                slug
                image {
                  url
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="mt-20">

          <section id="top" className="w-full md:px-10 relative">
            <div className="container mx-auto py-5 border-b border-gray-200 md:mb-10">
              <header className="flex flex-col-reverse md:flex-row w-full top-0 p-10 md:p-0">
                <div className="w-full md:mt-12 md:mt-0">
                  <div className="text-center md:text-left">
                    <h1 className="text-3xl md:text-5xl text-gray-700 font-bold leading-tight tracking-tight animated fadeIn">Agencies</h1>
                    <p className="inline-block text-md md:text-xl font-light text-gray-400 mx-auto mt-3">Flow Agencies</p>
                  </div>
                </div>
              </header>
            </div>
          </section>

          <AgencyComponent agencies={data.allStrapiAgency.edges} />
        </div>
      )}
    />

    <img className="w-full mt-24" src={waveReverse} alt="" />
    <CookiesModul />
    <Footer />
  </Layout>
)

export default LeadPages
